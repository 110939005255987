import { Link } from 'react-router-dom';
// import { Button, Input } from 'antd';
import { Rate, Button, notification } from 'antd';
import { useState } from 'react';
import FeedbackForm from './FeedbackForm';

const desc = ['Bad', 'Fine', 'Good', 'Very Good', 'Excellent'];

const submitFeedback = async (feedback, name, email) => {
  try {
    // make fetch post request to feedback endpoint
    const feedbackData = {
      name,
      email,
      feedback,
    };

    const feedbackRes = await fetch(`${process.env.REACT_APP_API_URL}/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedbackData),
    }).then((res) => res.json());

    if (feedbackRes.success) {
      notification.info({ message: 'Thank you', description: 'Your feedback goes a long way in improving our services.', placement: 'bottom' });
    } else {
      notification.error({ message: 'Error submitting feedback', description: 'Something went wrong. Please try again later.', placement: 'bottom' });
    }
  } catch (error) {
    notification.error({ message: 'Error submitting feedback', description: 'Something went wrong. Please try again later.', placement: 'bottom' });
  }
};

function PostDemoFollowUp() {
  const [rating, setRating] = useState(3);
  return (
    <div className="my-8 flex sm:flex-row flex-wrap justify-between">
      <div className="flex flex-col w-fit rounded-lg">
        <h1 className="text-xl font-bold mb-2">Did you like what you saw?</h1>
        <div className="flex flex-col w-3/4 h-fit border p-2 rounded-lg bg-slate-300">
          <p className="font-bold">How would you rate your experience with the responses above?</p>
          <div className="flex items-center w-full h-fit border justify-center p-4 my-2 rounded-lg bg-white">
            <span className="mr-4 flex items-center w-60">
              <Rate tooltips={desc} onChange={setRating} value={rating} />
              {rating ? <span className="ant-rate-text">{desc[rating - 1]}</span> : ''}
            </span>
            <Button className="font-bold" onClick={() => submitFeedback(JSON.stringify({ userRating: rating }))}>Submit</Button>
          </div>
        </div>
        <div className="flex flex-col text-base text-gray-800 p-2">
          <p>You can also have a similar widget placed on your site with a few simple steps.</p>
          <p className="font-bold">Sign up now and get exclusive discounts for paid plans.</p>
          <p>Let&apos;s get started with free signup here 👇</p>
        </div>
        <Link to="https://sso.telepilot.ai/signup" className="bg-primary hover:bg-primary-lighter text-gray-900 hover:text-gray-600 py-2 px-4 w-fit rounded-lg font-bold text-md">
          Get Started!
        </Link>
      </div>
      <div className="flex h-full flex-col sm:w-[40%] rounded-lg border items-center">
        <div className="flex flex-col h-full sm:w-5/6 self-center">
          <h1 className="text-xl font-bold mb-2">Have a feedback? We&apos;re all ears!</h1>
          <div className="flex w-full">
            <FeedbackForm submitFeedback={submitFeedback} />
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col my-8 hidden">
        <h1 className="text-lg font-bold mb-2">We have a special discount for your
        first paid plan with TelePilot AI. Get it delivered to your inbox 👇</h1>
        <div className="flex w-1/3">
          <Input placeholder="Enter your email" className="w-full mr-2" />
          <Button>
            Get Discount
          </Button>
        </div>
      </div> */}
    </div>
  );
}

export default PostDemoFollowUp;
