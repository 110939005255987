import { Link } from 'react-router-dom';

import ResourcesDpDown from './ResourcesDpDown';
import LogoWithNameBlack from '../../assets/brand/logo-with-name-black.svg';

const ctaStyle = 'bg-primary hover:bg-primary-lighter text-gray-900 hover:text-gray-600 py-2 px-4 w-fit rounded-full font-bold text-md';
const navLinksStyle = 'hidden sm:flex pr-2 lg:pr-8 sm:text-sm text-base lg:text-lg text-gray-600 hover:text-gray-900 transition-transform transform hover:-translate-y-0.5 active:scale-95';
const activeTabStyle = 'absolute bottom-0 left-0 w-1/2 border border-solid border-b-1 border-primary transition-all duration-300 ease-in-out';
const activeTextStyle = 'font-bold mb-1';

const links = [
  { href: 'https://www.telepilot.ai/product', label: 'product' },
  { href: 'https://www.telepilot.ai/solutions', label: 'solutions' },
  { href: 'https://www.telepilot.ai/pricing', label: 'pricing' },
  { href: 'https://demo.telepilot.ai', label: 'demo' },
];

function SiteHeader() {
  return (
    <div className="fixed flex top-0 w-full z-50 h-20 bg-white">
      <nav className="container mx-auto px-4 py-2 flex items-center justify-between text-sm lg:w-5/6">
        <Link to="https://www.telepilot.ai">
          <div className="flex items-center">
            <img src={LogoWithNameBlack} alt="TelePilot logo" width={150} height={150} />
          </div>
        </Link>

        {/* nav links */}
        {/* <div className="hidden md:flex flex-row text-xs lg:text-lg">
          {links.map(({ href, label }) => (
            <div key={label} className={`${navLinksStyle} relative`}>
              {label === 'demo' && <div className={activeTabStyle} />}
              <Link href={href} className={label === 'demo' ? activeTextStyle : ''}>
                {label}
              </Link>
            </div>
          ))}
          <ResourcesDpDown />
        </div> */}

        {/* nav links */}
        <div className="hidden md:flex flex-row text-xs lg:text-lg">
          {links.map(({ href, label }) => (
            <Link to={href} className={label === 'demo' ? activeTextStyle : ''}>
              <div key={label} className={`${navLinksStyle} relative`}>
                {label === 'demo' && <div className={activeTabStyle} />}
                {label}
              </div>
            </Link>
          ))}
          <ResourcesDpDown />
        </div>

        {/* cta buttons */}
        <div className="flex items-center">
          <Link to="https://sso.telepilot.ai/signin">
            <div className={navLinksStyle}>
              login
            </div>
          </Link>
          <Link to="https://sso.telepilot.ai/signup" className={ctaStyle}>
            Get Started for Free!
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default SiteHeader;
