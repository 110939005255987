import { Popover, Transition } from '@headlessui/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RssFeedIcon from '@mui/icons-material/RssFeed';

import { Fragment, useState } from 'react';

const solutions = [
  {
    name: 'FAQs',
    description: 'Have questions about TelePilot AI? Find answers to common queries',
    href: 'https://www.telepilot.ai/faq',
    icon: <QuestionAnswerIcon />,
  },
  {
    name: 'User Guide',
    description: 'Learn how to leverage our AI-powered customer support tool',
    href: 'https://www.telepilot.ai/user-guide',
    icon: <MenuBookIcon />,
  },
  {
    name: 'Blog',
    description: 'Get insights, tips, and discover how TelePilot AI is revolutionizing customer service',
    href: 'https://www.telepilot.ai/blog',
    icon: <RssFeedIcon />,
  },
];

export default function ResourcesDpDown() {
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <div className="w-full max-w-sm">
      <Popover
        className="relative"
      >
        {() => (
          <>
            <Popover.Button
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`
                ${open ? '' : 'text-opacity-90'}
                group border-hidden bg-white m-0 items-center self-center p-0 font-medium focus:outline-none hidden sm:flex sm:text-sm text-base lg:text-lg text-gray-600 hover:text-gray-900`}
            >
              <span>resources</span>
              <KeyboardArrowDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              show={open}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform sm:px-0 lg:max-w-3xl"
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white p-5 lg:grid-cols-2">
                    {solutions.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex h-10 w-10 shrink-0 items-center justify-center text-black sm:h-12 sm:w-12">
                          {item.icon}
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            {item.description}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                  <div className="bg-gray-50 p-2 px-4">
                    <a
                      href="https://www.telepilot.ai/contact"
                      className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    >
                      <span className="flex items-center">
                        <span className="text-sm font-medium text-gray-900">
                          Support
                        </span>
                      </span>
                      <span className="block text-sm text-gray-500">
                        Raise a support request and someone from our team will
                        get back to you
                      </span>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
