import { Layout } from 'antd';
import SiteHeader from '../components/common/SiteHeader';
import SiteFooter from '../components/common/SiteFooter';

const { Content } = Layout;

function MainLayout({ children }) {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();
  return (
    <>
      <div className="flex w-full h-full">
        <Layout>
          <SiteHeader />
          <Content className="mt-20 py-8 h-full bg-white">
            <div className="h-full w-5/6 mx-auto">
              {children}
            </div>
          </Content>
          <SiteFooter />
        </Layout>
      </div>
      {/* <div className="sm:hidden mt-20">
        <Layout>
          <SiteHeader />
          <Content
            className="bg-white rounded-lg my-8 mx-4 overflow-scroll h-[85vh] p-8"
          >
            <div className="bg-white rounded p-2 text-start">
              <div className="flex flex-col w-full">
                <h2 className="text-xl font-semibold mb-4">TelePilot AI Demo</h2>
                <h4 className="text-base font-semibold mb-4">Continue on a Tablet or Computer</h4>
                <p className="text-gray-800 w-full mb-4">
                  {`To continue with the demo of our services seamlessly, we recommend switching to a laptop, computer, or tablet.
                Certain steps, like adding links or providing text to train chat widget may be necessary.`}
                </p>
                <p className="text-gray-800 w-full">Please follow these instructions:</p>
                <p className="text-gray-800 w-full m-2">1. Open a web browser on your laptop, computer, or tablet.</p>
                <p className="text-gray-800 w-full m-2">2. Visit https://demo.telepilot.ai</p>
                <p className="text-gray-800 w-full m-2">3. Train the TelePilot AI with your custom data and see the magic unfold</p>
                <div>
                  <p className="text-gray-800 w-full mt-12">
                    If you have any additional questions, please feel free to contact us at
                    {' '}
                    <a href="mailto:support@telepilot.ai">
                      support@telepilot.ai
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Content>
          <SiteFooter />
        </Layout>
      </div> */}
    </>
  );
}
export default MainLayout;
