/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { Button } from 'antd';

export default function FeedbackForm({ submitFeedback }) {
  const [formState, setFormState] = useState({
    email: '',
    name: '',
    feedback: '',
  });

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await submitFeedback(formState.feedback, formState.name, formState.email);
    setFormState({
      email: '',
      name: '',
      feedback: '',
    });
  };

  return (
    <div className="flex w-5/6">
      <div className="w-full mx-auto py-6 bg-white rounded">
        <form onSubmit={handleSubmit}>
          <div className="w-full mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="name"
              type="text"
              placeholder="Name (optional)"
              name="name"
              value={formState.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email (optional)"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="feedback">
              Feedback
            </label>
            <textarea
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="feedback"
              type="text"
              placeholder="Feedback"
              name="feedback"
              value={formState.feedback}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <Button onClick={handleSubmit}>
              Submit Feedback
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
