import { useEffect, useState } from 'react';
// import { Divider } from 'antd';
import InputBox from '../demo/InputBox';
import ChatWidget from '../demo/ChatWidget';
import SetupInstructions from '../demo/SetupInstructions';
import ChatInstructions from '../demo/ChatInstructions';
import PostDemoFollowUp from '../demo/PostDemoFollowUp';
import ProgressPopup from '../demo/ProgressPopup';

function DemoPage() {
//   const [websiteLink, setWebsiteLink] = useState('');
//   const [helpText, setHelpText] = useState('');
  const [convoReady, setConvoReady] = useState(false);
  const [convoId, setConvoId] = useState();
  const [chatActive, setChatActive] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [demoTaken, setDemoTaken] = useState(false);

  useEffect(() => {
    const lsConvoId = localStorage.getItem('convoId');
    if (lsConvoId) {
      setConvoId(lsConvoId);
      setChatActive(true);
    }
  }, [chatActive]);

  const activateChat = () => {
    setOpenProgress(false);
    setChatActive(true);
  };

  const resetChat = () => {
    // remove convoId from localstorage
    localStorage.removeItem('convoId');
    setChatActive(false);
  };

  const handleConvoReady = (state) => {
    setConvoReady(state);
  };

  return (
    <>
      {/* DESKTOP VIEW */}
      <div className="hidden md:flex flex-col font-nunito">
        <div className="flex container">
          <div className="flex flex-col w-3/5 h-full py-4 mr-4">
            <div className="flex flex-col">
              <h1 className="text-2xl font-bold mb-2">TelePilot AI Demo - Experience the Power of TelePilot AI</h1>
              <p className="text-lg text-gray-800 mb-4">See how TelePilot AI can revolutionize your customer support in real-time.</p>
            </div>
            {
          chatActive ? (
            <div className="flex flex-col">
              <ChatInstructions resetChat={resetChat} />
            </div>
          )
            : (
              <div className="my-4">
                <InputBox setOpenProgress={setOpenProgress} handleConvoReady={handleConvoReady} />
              </div>
            )
        }
          </div>
          {/* <Divider type="vertical" className="h-full text-black bg-black" /> */}
          <div className="h-full w-2/5 flex items-center justify-center ml-4">
            {chatActive
              ? (
                <div className="h-full w-full flex items-center justify-center p-4">
                  <ChatWidget chatActive={chatActive} convoId={convoId} setDemoTaken={setDemoTaken} />
                </div>
              )
              : (
                <div className="h-full flex items-center justify-center p-4">
                  <SetupInstructions />
                </div>
              )}
          </div>
        </div>
        <div className="my-8">
          {demoTaken && (
          <PostDemoFollowUp />
          )}
        </div>
        <ProgressPopup activateChat={activateChat} open={openProgress} convoReady={convoReady} />
      </div>
      {/* MOBILE VIEW  */}
      <div className="sm:hidden flex flex-col font-nunito">
        <div className="flex flex-wrap container">
          <div className="flex flex-col h-full">
            <div className="flex flex-col">
              <h1 className="text-2xl font-bold mb-2">TelePilot AI Demo - Experience the Power of TelePilot AI</h1>
              <p className="text-lg text-gray-800 mb-4">See how TelePilot AI can revolutionize your customer support in real-time.</p>
            </div>
            <div className="h-full flex items-center justify-center ml-4">
              {chatActive
                ? (
                  <div className="h-full w-full flex items-center justify-center">
                    <ChatWidget chatActive={chatActive} convoId={convoId} setDemoTaken={setDemoTaken} />
                  </div>
                )
                : (
                  <div className="h-full w-full flex items-center justify-center sm:p-4">
                    <SetupInstructions />
                  </div>
                )}
            </div>
            {
          chatActive ? (
            <div className="flex flex-col">
              <ChatInstructions resetChat={resetChat} />
            </div>
          )
            : (
              <div className="my-4">
                <InputBox setOpenProgress={setOpenProgress} handleConvoReady={handleConvoReady} />
              </div>
            )
        }
          </div>
          {/* <Divider type="vertical" className="h-full text-black bg-black" /> */}
        </div>
        <div className="my-8">
          {demoTaken && (
          <PostDemoFollowUp />
          )}
        </div>
        <ProgressPopup activateChat={activateChat} open={openProgress} convoReady={convoReady} />
      </div>
    </>
  );
}

export default DemoPage;
