import { Button } from 'antd';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';

function ChatInstructions({ resetChat }) {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="hidden md:flex items-center">
        <h3 className="mr-2 text-xl font-bold">Your custom TelePilot AI is ready</h3>
        <ArrowRightAltIcon />
      </div>
      <p className="text-lg">
        Congratulations, Your custom TelePilot AI
        trained on data you provided is now ready for taking up questions!
      </p>
      <p className="hidden md:flex text-lg">Hit it up with any questions you have on the widget on right side.</p>
      <p className="sm:hidden text-lg">Hit it up with any questions you have on the widget above.</p>
      <div className="text-base">
        <h4>
          Want to test on some other data?
          You can train TelePilot AI with some other data as well.
        </h4>
        <p>
          Click on the
          {' '}
          <strong>Train with new data</strong>
          {' '}
          button below to train TelePilot AI with new data.
        </p>
        <Button className="my-4" onClick={resetChat}>Train with new data</Button>
      </div>
      <div className="flex sm:flex-row flex-col items-center">
        <p className="font-bold text-lg sm:mr-2">Get this widget on your site for free</p>
        <Link to="https://sso.telepilot.ai/signup">
          <Button
            type="button"
            className="flex bg-primary hover:bg-primary-lighter font-semibold items-center text-gray-800 hover:text-gray-900"
          >
            <p className="mx-2 text-sm">
              Integrate on your site for free
            </p>
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ChatInstructions;
