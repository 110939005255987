/* eslint-disable no-param-reassign */
import { Button, Modal, Progress } from 'antd';
import { useEffect, useState, useRef } from 'react';

function ProgressPopup({ open, activateChat, convoReady }) {
  const [progressPercent, setProgressPercent] = useState(0);
  const convoReadyRef = useRef(convoReady); // Create a ref

  useEffect(() => {
    convoReadyRef.current = convoReady; // Update the ref when convoReady changes
  }, [convoReady]);

  useEffect(() => {
    let interval;
    if (open) {
      setProgressPercent(0);
      interval = setInterval(() => {
        setProgressPercent((prevProgressPercent) => {
          if (prevProgressPercent < 100) {
            prevProgressPercent += Math.floor(70 * Math.random(0, 10));
          }
          // wait till convoReady is true before making it `100`
          if (prevProgressPercent >= 100 && !convoReadyRef.current) {
            prevProgressPercent = 99;
          }
          return prevProgressPercent;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        title={progressPercent < 100 ? 'Creating your customised TelePilot AI' : 'Your customised chat widget is ready'}
        footer={(
          <Button disabled={progressPercent < 100} onClick={activateChat}>
            Let&apos;s go!
          </Button>
          )}
      >
        {progressPercent < 100 ? (
          <p>
            Please wait while we train on the data you provided.
            Your chat widget will be ready soon.
          </p>
        ) : (
          <p>
            Your chat widget is ready. Please check it out on the right side.
          </p>
        )}
        <Progress
          percent={progressPercent}
          status="active"
          strokeColor={{
            from: '#108ee9',
            to: '#87d068',
          }}
        />
      </Modal>
    </div>
  );
}

export default ProgressPopup;
