import {
  useEffect, useState, useRef, useCallback,
} from 'react';

import {
  Button, Input, message as antMessage,
} from 'antd';
import SendIcon from '@mui/icons-material/Send';
import TelePilotIconBlack from '../../assets/brand/tp_logo_final_black.svg';
import TypingIndicator from '../common/TypingIndicator';

function ChatWidget({ convoId, setDemoTaken }) {
  const [message, setMessage] = useState('');
  const [conversations, setConversations] = useState([{
    id: 0, message: 'Hello, how can i help you today? I am trained on the data you provided, go ahead and ask me any question regarding it.', sender: 'agent', timestamp: new Date().toISOString(),
  }]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [fairUsageMessage, setFairUsageMessage] = useState();
  const widgetRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    if (widgetRef.current) {
      const { scrollHeight } = widgetRef.current;
      const { clientHeight } = widgetRef.current;
      const maxScrollTop = scrollHeight - clientHeight;
      widgetRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, []);

  const userStyle = 'bg-neutral1 text-white rounded-lg px-4 py-2 my-2 text-right self-end w-fit';
  const assistantStyle = 'bg-accent1-lighter text-white rounded-lg p-2 my-2 w-3/4 text-left';

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  // TODO: add auto refresh functionality with timer if message refresh issue persists

  const sendMessage = async () => {
    try {
      if (message.trim() !== '') {
        const convo = {
          message,
          convoId,
          sender: 'user',
        };
        setConversations((prevConversations) => [...prevConversations, convo]);
        const userMessage = message;
        setMessage('');
        setResponseLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/message`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: userMessage,
            convoId,
          }),
        }).then((res) => res.json())
          .catch(() => {
            antMessage.error('Something went wrong. Please try again later.');
          });
        setResponseLoading(false);
        if (response.success) {
          setConversations((prevConversations) => [...prevConversations, {
            message: response.response.content,
            sender: 'assistant',
          }]);
        } else {
          if (response.reason) {
            const failureReason = response.reason;
            if (failureReason === 'messageLimitReached') {
              setFairUsageMessage('You have reached the maximum number of messages allowed for this demo. Please try again later.');
            } else if (failureReason === 'userBlocked') {
              setFairUsageMessage('Your conversation was flagged in violation of our terms of service. Please contact us at support@telepilot.ai if you think this is a mistake.');
            }
          }
          antMessage.error(response.message);
        }
      }
      setDemoTaken(true);
    } catch (error) {
      // pop last message from conversations
      setConversations((prevConversations) => prevConversations.slice(0, -1));
      antMessage.error('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="relative w-[97%] h-full group">
        <div className="absolute -inset-0.5 opacity-75 bg-gradient-to-b from-accent1 to-amber-600 rounded-lg blur group-hover:opacity-50 transition duration-200" />
        <div className="relative flex flex-col text-black rounded-xl justify-around w-full">
          <div className="flex flex-col text-white bg-gradient-to-b rounded-t-xl border-primary from-accent1 to-accent2">
            <div className="py-2 px-4 text-base">
              <h3>Hi there! 👋 </h3>
              <h3>Need help with something?</h3>
            </div>
          </div>
          <div className="h-3/6 bg-white">
            {/* <div className="p-4"> */}
            <div ref={widgetRef} className="flex flex-col h-96 overflow-scroll p-4">
              {conversations && conversations.map((conversation) => (
                <div key={conversation.message} className={`mb-2 ${conversation.sender.includes('user') ? userStyle : assistantStyle}`}>
                  {/* <h3>{conversation.sender}</h3> */}
                  {conversation.message}
                </div>
              ))}
              {responseLoading && <TypingIndicator />}
            </div>
            {fairUsageMessage
              ? (
                <div>
                  <div className="flex justify-between items-center px-2 py-4 border mx-2 rounded-lg">
                    <span>{fairUsageMessage}</span>
                  </div>
                </div>
              )
              : (
                <div className="flex justify-between items-center px-2 py-4">
                  <Input
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onPressEnter={sendMessage}
                    className="w-full mr-2"
                    disabled={responseLoading}
                  />
                  <Button
                    className="border-hidden"
                    disabled={responseLoading}
                    icon={<SendIcon />}
                    onClick={sendMessage}
                  />
                </div>
              )}
          </div>
          <div className="bg-white rounded-b-lg">
            <div className="text-xs self-center flex justify-center opacity-20 hover:opacity-100">
              <a href="https://www.telepilot.ai" target="_blank" rel="noreferrer">
                <img src={TelePilotIconBlack} alt="TelePilot AI" width={24} height={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatWidget;
