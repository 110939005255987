function SetupInstructions() {
  return (
    <div className="flex flex-col p-8 rounded-lg bg-white shadow-2xl justify-center">
      <h2 className="text-xl font-bold text-gray-900 mb-4">Instructions</h2>
      <div className="mb-4 flex flex-col">
        <p className="text-base text-gray-800">
          <strong>Step 1: </strong>
          Select an input method.
        </p>
        <p className="text-sm text-gray-800 m-2">
          <strong>Web Link:</strong>
          {' '}
          Use the chatbot trained on data from your own website.
        </p>
        <p className="mx-2 text-sm">or</p>
        <p className="text-sm text-gray-800 m-2">
          <strong>Custom Text:</strong>
          {' '}
          Use the chatbot trained on a help text provided by you.
        </p>
      </div>
      <div className="mb-4">
        <p className="text-base text-gray-800">
          <strong>Step 2: </strong>
          Create your TelePilot.
        </p>
        <p className="text-sm text-gray-800 p-2">
          Click on the
          {' '}
          <strong>&quot;Create my TelePilot&quot;</strong>
          {' '}
          button to create your custom AI Co-Pilot for support.
        </p>
      </div>
    </div>
  );
}

export default SetupInstructions;
