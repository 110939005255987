import { useSpring, animated } from 'react-spring';

function TypingIndicator() {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <div className="w-10 h-5 mx-4 my-6 flex items-end justify-between">
      <animated.div style={props} className="w-1.5 h-1.5 bg-gray-700 rounded-full animate-bounce" />
      <animated.div style={props} className="w-1.5 h-1.5 bg-gray-700 rounded-full animate-bounce200" />
      <animated.div style={props} className="w-1.5 h-1.5 bg-gray-700 rounded-full animate-bounce400" />
    </div>
  );
}

export default TypingIndicator;
