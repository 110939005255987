import { useState } from 'react';
import {
  Tabs, Input, Tag, Form, message, Space, Button,
} from 'antd';
import { LinkOutlined } from '@mui/icons-material';

const { TabPane } = Tabs;
const { TextArea } = Input;

const maximumAllowedLinks = process.env.REACT_APP_MAX_LINKS || 1;

function InputBox({ setOpenProgress, handleConvoReady }) {
  const [source, setSource] = useState('WEB');
  const [refText, setRefText] = useState('');
  const [webLinks, setWebLinks] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [form] = Form.useForm();

  const customTextDescription = `This could be information about your product or service, frequently asked questions and their answers, 
  help center articles, tutorials, or any text that helps the TelePilot AI understand and provide the necessary information. 
  The more detailed and specific your input, the better TelePilot AI can assist.`;

  const customTextPlaceholder = `Examples:
  - 'Our product XYZ is a revolutionary device designed to...'
  - 'Question: How does the warranty for XYZ product work? Answer: The warranty for the XYZ product lasts for...'
  - 'In case of a technical glitch, first, ensure your device is connected to...'`;

  const weblinkDescription = `Add links to a product description, FAQ page, help center article, blog post, or
  any other relevant webpage
  you want the TelePilot AI to learn from.`;

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleRefTextChange = (e) => {
    setRefText(e.target.value);
  };

  const validateURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name and extension
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
      + '(\\:\\d+)?' // port
      + '(\\/[-a-z\\d%@_.~+&:]*)*' // path
      + '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' // query string
      + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
  };

  const handleInputConfirm = () => {
    if (validateURL(inputValue) && webLinks.length < maximumAllowedLinks) {
      setWebLinks([...webLinks, inputValue]);
      setInputValue('');
      form.setFields([{
        name: 'webLink',
        value: '',
        errors: [],
      }]);
    } else if (!validateURL(inputValue)) {
      form.setFields([{
        name: 'webLink',
        errors: ['Invalid URL!'],
      }]);
    } else if (webLinks.length >= maximumAllowedLinks) {
      form.setFields([{
        name: 'webLink',
        errors: [`Maximum of ${maximumAllowedLinks} URLs can be added`],
      }]);
    }
  };

  const handleTabChange = (key) => {
    setSource(key);
    setInputValue('');
  };

  const createNewConvo = async () => {
    try {
      setOpenProgress(true);
      let learningMaterial;

      if (source === 'CUSTOM') {
        learningMaterial = { refText };
      } else {
        learningMaterial = { weblinks: webLinks };
      }
      // call create new convo api
      const convo = await fetch(`${process.env.REACT_APP_API_URL}/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source,
          ...learningMaterial,
        }),
      }).then((res) => res.json())
        .catch(() => {
          setOpenProgress(false);
          message.error('Something went wrong. Please try again later.');
        });

      if (convo.success) {
        localStorage.setItem('convoId', convo.convoId);
        handleConvoReady(true);
      } else {
        setOpenProgress(false);
        message.error(convo.message);
      }
    } catch (error) {
      setOpenProgress(false);
      message.error('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="w-full">
      <Tabs activeKey={source} onChange={handleTabChange}>
        <TabPane tab="Web Link" key="WEB">
          <Form form={form}>
            <p className="text-base text-gray-600 mb-2">
              {weblinkDescription}
            </p>
            <Form.Item name="webLink">
              <Space.Compact className="mt-2 w-[95%]">
                <Input
                  addonBefore={<LinkOutlined />}
                  type="text"
                  size="large"
                  className="overflow-scroll"
                  placeholder="Insert link and press enter"
                  value={inputValue}
                  onChange={handleInputChange}
                  onPressEnter={handleInputConfirm}
                />
                <Button type="primary" onClick={handleInputConfirm} size="large">Add</Button>
              </Space.Compact>
            </Form.Item>
          </Form>
          <p className="m-2 my-4">
            Added Links:
            {' '}
            {webLinks.length}
          </p>
          <div
            className="flex flex-col items-start justify-start justify-items-start w-[95%] h-20 my-2 overflow-y-scroll overflow-x-scroll border border-solid border-gray-200 rounded"
          >
            {
                  webLinks.length > 0 ? webLinks.map((link) => (
                    <Tag
                      key={link}
                      closable
                      onClose={() => setWebLinks(webLinks.filter((l) => l !== link))}
                      className="w-fit h-fit m-1 flex items-center"
                      color="geekblue"
                    >
                      {link}
                    </Tag>
                  )) : <p className="text-gray-400 m-2">No links added yet</p>
                }
          </div>
        </TabPane>
        <TabPane tab="Custom Text" key="CUSTOM">
          <p className="text-base text-gray-600 mb-2">
            {customTextDescription}
          </p>
          <TextArea
            className="my-2 overflow-scroll"
            value={refText}
            onChange={handleRefTextChange}
            placeholder={customTextPlaceholder}
            autoSize={{ minRows: 10, maxRows: 16 }}
          />
        </TabPane>
      </Tabs>
      <Button
        onClick={createNewConvo}
        // className="bg-blue-500 hover:bg-blue-700 text-white rounded
        // focus:outline-none focus:shadow-outline"
        type="primary"
        disabled={(source === 'WEB' && webLinks.length === 0) || (source === 'CUSTOM' && refText.length === 0)}
      >
        Create my TelePilot
      </Button>
    </div>
  );
}

export default InputBox;
