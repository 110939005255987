import { Link } from 'react-router-dom';
import LogoWithNameBlack from '../../assets/brand/logo-with-name-black.svg';
import XLogoWhite from '../../assets/logos/logo-x-white.png';
import LinkedInLogoBlack from '../../assets/logos/LI-In-Bug.png';

function SiteFooter() {
  return (
    <footer className="bg-primary w-full text-sm p-2 pb-12 justify-center">
      <div className="flex flex-col justify-around container mx-auto h-full w-full">
        <div className="w-5/6 flex items-center justify-center lg:justify-start">
          <Link to="https://www.telepilot.ai">
            <div>
              <img src={LogoWithNameBlack} alt="telepilot logo" width={150} height={150} />
            </div>
          </Link>
        </div>
        <div className="flex flex-col lg:flex-row flex-wrap items-center">
          <div className="w-5/6 lg:w-2/6 text-justify">
            <span className="">
              {`Unleash the Power of AI for Effortless Customer Support. Our intelligent platform automates 
                and enhances your customer service operations, delivering instant responses, personalized interactions, 
                and seamless support across all channels. Say goodbye to support headaches and hello to a smarter, 
                more efficient way of serving your customers. Elevate your customer support game with TelePilot today!`}
            </span>
          </div>
          <div className="flex lg:flex-row lg:w-4/6 lg:justify-end justify-around lg:m-0">
            <div className="flex lg:flex-row lg:w-4/6 w-full justify-around items-center lg:items-start">
              <div className="flex flex-col m-4 lg:m-0 justify-between h-44 lg:h-44  underline lg:no-underline">
                <Link to="https://www.telepilot.ai/about" className=" text-black hover:text-gray-600">
                  About Us
                </Link>
                <Link to="https://www.telepilot.ai/contact" className=" text-black hover:text-gray-600">
                  Contact Us
                </Link>
                <Link to="https://www.telepilot.ai/careers" className=" text-black hover:text-gray-600">
                  Careers
                </Link>
                <Link to="https://www.telepilot.ai/affiliate" className=" text-black hover:text-gray-600">
                  Affiliate
                </Link>
                <Link to="https://www.telepilot.ai/terms" className=" text-black hover:text-gray-600">
                  Terms of Use
                </Link>
                <Link to="https://www.telepilot.ai/privacy" className=" text-black hover:text-gray-600">
                  Privacy Policy
                </Link>
                <Link to="https://www.telepilot.ai/payment-policy" className=" text-black hover:text-gray-600">
                  Payment Policy
                </Link>
              </div>
              <div className="flex flex-col m-4 lg:m-0 lg:justify-between h-44 lg:h-24 underline lg:no-underline">
                <Link to="https://www.telepilot.ai/faq" className=" text-black hover:text-gray-600">
                  FAQ
                </Link>
                <Link to="https://demo.telepilot.ai" className=" text-black hover:text-gray-600">
                  Demo
                </Link>
                <Link to="https://www.telepilot.ai/user-guide" className=" text-black hover:text-gray-600">
                  User Guide
                </Link>
                <Link to="https://www.telepilot.ai/feedback" className=" text-black hover:text-gray-600">
                  Feedback
                </Link>
              </div>
              <div className="flex flex-col m-4 lg:m-0 lg:justify-between h-44 lg:h-32 underline lg:no-underline">
                <Link to="https://www.telepilot.ai/" className=" text-black hover:text-gray-600">
                  Home
                </Link>
                <Link to="https://www.telepilot.ai/product" className=" text-black hover:text-gray-600">
                  Product
                </Link>
                <Link to="https://www.telepilot.ai/solutions" className=" text-black hover:text-gray-600">
                  Solutions
                </Link>
                <Link to="https://www.telepilot.ai/pricing" className=" text-black hover:text-gray-600">
                  Pricing
                </Link>
                <Link to="https://www.telepilot.ai/blog" className=" text-black hover:text-gray-600">
                  Blog
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start lg:w-full w-5/6 self-center">
          <div className="flex">
            <a className="w-fit h-fit mr-4" href="https://www.twitter.com/TelePilotAI" target="_blank" rel="noreferrer">
              <div className="flex my-4 bg-black w-fit h-fit rounded-lg p-1">
                <img alt="twitter" src={XLogoWhite} width={20} height={20} />
              </div>
            </a>
            <a className="w-fit h-fit mr-4" href="https://www.linkedin.com/company/telepilotai" target="_blank" rel="noreferrer">
              <div className="my-4 w-fit">
                <img alt="linkedin" src={LinkedInLogoBlack} width={35} height={30} />
              </div>
            </a>
          </div>
          <p className="my-4">
            &copy;
            {new Date().getFullYear()}
            {' '}
            TelePilot AI (by DeepBliss Works).
          </p>
          <p>
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
